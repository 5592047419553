import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const EyeSlash = createSvgIcon(
  <svg viewBox="0 0 1000 1000">
    <path d="M355 500q0 39 20 72t53 53 72 20 73-20 53-53 19-72-19-73-53-53-73-19-72 19-53 53-20 73zm145-371q-61 0-119 16-52 14-102 42-44 25-86 59-36 31-70 69-28 31-54 68-21 29-39 59-12 22-22 42l-8 16 9 16q12 20 26 42 20 30 42 59 28 37 58 68 35 38 72 69 42 34 86 59 48 28 98 42 54 16 109 16t109-16q50-14 98-42 44-25 86-59 37-31 72-69 30-31 58-68 22-29 42-59 14-22 26-42l9-16-8-16q-10-20-22-42-18-30-39-59-26-37-54-68-34-38-70-69-42-34-86-59-50-28-102-42-58-16-119-16zm0 645q-75 0-137-37T263 637t-37-137 37-138 100-99 137-37 138 37 99 99 37 138-37 137-99 100-138 37zm116-274l326-329 23-23L852 35 500 384 384 500 35 852l113 113 352-349z" />
  </svg>,
  'EyeSlash'
);

export default EyeSlash;
