import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

// Error icon modified from InfoStroke
const Error = createSvgIcon(
  <svg viewBox="0 0 24 24">
    <g transform="translate(12.000000, 12.000000) rotate(-180.000000) translate(-12.000000, -12.000000) translate(2.000000, 2.000000)">
      <path d="M10,2.20689655 C11.4099617,2.20689655 12.7203065,2.56704981 13.9310345,3.28735632 C15.1111111,3.96168582 16.0383142,4.88888889 16.7126437,6.06896552 C17.4329502,7.27969349 17.7931034,8.59003831 17.7931034,10 C17.7931034,11.4099617 17.4329502,12.7203065 16.7126437,13.9310345 C16.0383142,15.1111111 15.1111111,16.0383142 13.9310345,16.7126437 C12.7203065,17.4329502 11.4099617,17.7931034 10,17.7931034 C8.59003831,17.7931034 7.27969349,17.4329502 6.06896552,16.7126437 C4.88888889,16.0383142 3.96168582,15.1111111 3.28735632,13.9310345 C2.56704981,12.7203065 2.20689655,11.4099617 2.20689655,10 C2.20689655,8.59003831 2.56704981,7.27969349 3.28735632,6.06896552 C3.96168582,4.88888889 4.88888889,3.96168582 6.06896552,3.28735632 C7.27969349,2.56704981 8.59003831,2.20689655 10,2.20689655 Z M10,0 C8.17624521,0 6.49042146,0.45210728 4.94252874,1.35632184 C3.42528736,2.22988506 2.22988506,3.42528736 1.35632184,4.94252874 C0.45210728,6.49042146 -3.44125751e-14,8.17624521 -3.44125751e-14,10 C-3.44125751e-14,11.8237548 0.45210728,13.5095785 1.35632184,15.0574713 C2.22988506,16.5747126 3.42528736,17.7701149 4.94252874,18.6436782 C6.49042146,19.5478927 8.17624521,20 10,20 C11.8237548,20 13.5095785,19.5478927 15.0574713,18.6436782 C16.5747126,17.7701149 17.7701149,16.5747126 18.6436782,15.0574713 C19.5478927,13.5095785 20,11.8237548 20,10 C20,8.17624521 19.5478927,6.49042146 18.6436782,4.94252874 C17.7701149,3.42528736 16.5747126,2.22988506 15.0574713,1.35632184 C13.5095785,0.45210728 11.8237548,0 10,0 Z M8.89655172,9.13793103 L11.1034483,9.13793103 L11.1034483,15.0689655 L8.89655172,15.0689655 L8.89655172,9.13793103 Z M8.89655172,5.17241379 L11.1034483,5.17241379 L11.1034483,7.40229885 L8.89655172,7.40229885 L8.89655172,5.17241379 Z" />
    </g>
  </svg>,
  'Error'
);

export default Error;
