import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const CaretDown = createSvgIcon(
  <svg viewBox="0 0 1000 1000">
    <path d="M503 819l462-532-123-110-339 394-345-394L35 287z" />
  </svg>,
  'CaretDown'
);

export default CaretDown;
