import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

// HYDS search icon scaled slightly smaller
const Search = createSvgIcon(
  <svg viewBox="0 0 24 24" version="1.1">
    <g transform="translate(2.000000, 2.000000)">
      <path d="M18.8613203,17.288 L19.2488,17.756 L17.0073789,20 L12.3437128,15.332 C11.7197711,15.686 11.0541008,15.958 10.3467019,16.148 C9.63930307,16.338 8.92793005,16.434 8.20860873,16.434 C6.72823751,16.434 5.34920714,16.06 4.0735047,15.312 C2.83754376,14.592 1.85394141,13.614 1.12071056,12.376 C0.373570187,11.098 -4.2357061e-16,9.716 -4.2357061e-16,8.228 C-4.2357061e-16,6.74 0.371583111,5.356 1.11872349,4.078 C1.85195433,2.84 2.83754376,1.856 4.0735047,1.122 C5.34920714,0.374 6.73022459,0 8.21854411,0 C9.70686363,0 11.0878811,0.374 12.3635835,1.122 C13.5995445,1.856 14.5771856,2.842 15.2965069,4.078 C16.0436473,5.356 16.4172175,6.736 16.4172175,8.216 C16.4172175,9.1 16.2781222,9.96 15.9999316,10.796 C15.7217411,11.632 15.3183647,12.416 14.7878156,13.15 L18.8613203,17.288 Z M8.07150053,13.124 C8.96171033,13.124 9.78833373,12.9 10.5553449,12.452 C11.322356,12.004 11.9264269,11.394 12.3715318,10.624 C12.8166367,9.854 13.0391892,9.02 13.0391892,8.124 C13.0391892,7.228 12.8166367,6.398 12.3715318,5.634 C11.9264269,4.87 11.3203689,4.26 10.5553449,3.806 C9.7903208,3.352 8.96171033,3.124 8.07150053,3.124 C7.18129072,3.124 6.3566544,3.352 5.59759158,3.806 C4.83852875,4.26 4.23247073,4.87 3.7814046,5.634 C3.33033847,6.398 3.10381187,7.228 3.10381187,8.124 C3.10381187,9.02 3.33033847,9.852 3.7814046,10.624 C4.23247073,11.396 4.83852875,12.004 5.59759158,12.452 C6.3566544,12.9 7.18129072,13.124 8.07150053,13.124 Z" />
    </g>
  </svg>,
  'Search'
);

export default Search;
